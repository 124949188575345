import React, { useRef } from "react";

function Logout(props) {
    // localStorage.removeItem('log');
    // const log = localStorage.getItem('log');

    //props.setLog(false);
    //console.log("log in Logout is", log);
    localStorage.setItem('logStorage', false);
    localStorage.setItem('idUser', '');
    window.location.href = "/";
    return (
        <div className="page form-page">
            <h3 className="mb-3">Выход выполнен!</h3>
        </div>
    );
}

export default Logout;
