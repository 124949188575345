import {NavLink} from "react-router-dom";
function Footer() {
    return (
        <div id="footer-bar" className="footer-bar-1">
            <NavLink className="nav-link color-google" to="/about"><i className="fa fa-info-circle"> </i><span>О проекте</span></NavLink>
            <NavLink className="nav-link color-blue-dark" to="/create"><i className="fa fa-plus-square"> </i><span>Добавить</span></NavLink>
            <NavLink className="nav-link color-orange-dark" to="/"><i className="fa fa-home"> </i><span>Главная</span></NavLink>
            <NavLink className="nav-link color-red-dark" to="/tech"><i className="fa fa-cogs"> </i><span>Техника</span></NavLink>
            <NavLink className="nav-link color-green-dark" to="/contacts"><i className="fa fa-map-marker-alt"> </i><span>Контакты</span></NavLink>
        </div>
    );
}
export default Footer;
