import {useParams} from 'react-router-dom'
import {useEffect, useState} from "react";
import { Offline, Online } from "react-detect-offline";
import env from "../env";

import {YMaps, Map, Placemark, Clusterer, FullscreenControl, GeolocationControl, SearchControl, ZoomControl } from 'react-yandex-maps';



function Tech() {

    let {id} = useParams(); // считываем id из url
    const [tech, setTech] = useState([]);
    const [info, setInfo] = useState('Идет загрузка...');
    const [ymap, setYmap] = useState(<div id="preloader"><div className="spinner-border color-highlight"> </div></div>);

    let techArr = [];
    let techStr = [];

    const center = [55.76, 37.64];

    let sendData = (obj) => {
        fetch(env.url+"/rent/ajax/app.php", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(obj)
        })
            .then (response => response.json())
            .then (response =>{
                console.log(response);
                if(response.message) {
                    setTech(response.message);
                    console.log(response.message);
                }
            });
    };

    if(id === undefined) id = 'all';
    useEffect(()=>{
        if(id !== undefined) {
            fetch(env.url+"/rent/ajax/app.php", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify({'action': 'view', 'id': id})
            })
            .then(response => response.json())
            .then (response => {
                if(response.message) {

                    techArr = response.message; // return object
                    let center = [techArr[0].coords.split(',')[0], techArr[0].coords.split(',')[1]];

                    techArr.map((item, index) => (
                        // console.log(item.category)
                        console.log(`imgs/${item.category!==""?item.category.toString().toLowerCase():'default'}.svg`)
                    ));

                    setYmap(
                        <YMaps>
                            <Map defaultState={{
                                center,
                                zoom: 10
                            }}
                            width="100%"
                            height="100vh"
                            >
                                <Clusterer
                                    options={{
                                        preset: "islands#invertedRedClusterIcons",
                                        groupByCoordinates: false,
                                    }}
                                >
                                    {techArr.map((item, index) => (
                                        <Placemark
                                            key={index}
                                            id = {item.id}
                                            geometry= {[item.coords.split(',')[0], item.coords.split(',')[1]]}
                                            modules={['geoObject.addon.balloon']} // модуль Yandex.Maps API для балунов
                                            properties={{
                                                balloonContentBody: `${item.name} <br /> 
                                                    ${item.user_name} ${item.user_last_name} <br /> 
                                                   <a href="tel:${item.work_phone}">${item.work_phone}</a>`
                                            }}
                                            options={{
                                                draggable: true,
                                                iconLayout: "default#image",
                                                iconImageSize: [30, 30],
                                                iconImageHref: `imgs/${item.category!==""?item.category.toString().toLowerCase():'default'}.svg`
                                            }}
                                            onDragEnd={
                                                e => {
                                                    console.log(`${e.get('target').geometry.getCoordinates()[0]},${e.get('target').geometry.getCoordinates()[1]}`);
                                                    sendData({
                                                        'id': item.id,
                                                        'action': 'setcoords',
                                                        'coords': `${e.get('target').geometry.getCoordinates()[0]},${e.get('target').geometry.getCoordinates()[1]}`
                                                    })
                                                }
                                            }
                                        />
                                    ))}
                                </Clusterer>

                                <FullscreenControl />
                                <GeolocationControl options={{ float: 'left'}} />
                                <SearchControl options={{ float: 'right'}} />
                                <ZoomControl options={{ float: 'right'}} />

                            </Map>
                        </YMaps>
                    );

                    techArr.forEach((item, index) =>
                        techStr.push(
                            <div key={index+200}>
                                <p>name: {item.name}</p>
                                <p>category: {item.category}</p>
                                <p>coords: {item.coords}</p>
                                <hr />
                            </div>,
                        ),
                    );

                    setInfo(techStr);
                }
                else{
                    setInfo('Ошибка сети!');
                }
            })
            .catch((error) => {
                setInfo("Ошибка получения данных с сервера. [" + error.message + "]");
            });

        }
    }, []);


    return (
        <div className="page">
            {ymap}
            {/*{info}*/}
            {/*{tech}*/}
        </div>
    );
}

export default Tech;
