import React, {useEffect, useState} from 'react';
import './App.css';

import {BrowserRouter as Router, Routes, Route, NavLink, Link} from 'react-router-dom';
import { Offline, Online } from "react-detect-offline";

import About from "./components/About";
import Create from "./components/Create";
import Error from "./components/Error";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Main from "./components/Main";
import Note from "./components/Note"
import Tech from "./components/Tech";

import Login from "./components/Login";
import Logout from "./components/Logout";
import Signup from "./components/Signup";
import Contacts from "./components/Contacts";

function App() {
    // const [conn, connUp] = useState(false);
    // const [log, logUp] = useState(false);
    // const setConn = (est) => {connUp(est); };
    // const setLog = (est) => {logUp(est); localStorage.setItem('logStorage', est);};

    const logStorage = localStorage.getItem('logStorage');

    return (
          <Router>
            <Header />
            <main role = "main" className = "flex-shrink-0" >
              <div className = "container" >
            <Routes>
                <Route exact path="/" element={<Main />} />
                <Route path="/about" element={<About />} />
                <Route path="/create"  element={(localStorage.getItem('logStorage') === 'true') ? <Create /> :  <Login />} />
                <Route path="/note" element={<About />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route exact path="/tech/" element={<Tech />} />
                <Route exact path="/tech/:id" element={<Tech />} />
                <Route exact path="/note/" element={<Note />} />
                <Route exact path="/note/:id" element={<Note />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="*" element={<Error />} status={404}/>
            </Routes>
              </div>
            </main>
            <Footer />
          </Router>


  );
}

export default App;
