import {NavLink} from "react-router-dom";
import React from "react";
import { Offline, Online } from "react-detect-offline";

function Header() {
    return (
        <header className="header">
            <nav className="navbar navbar-expand-lg bg-light">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">ЕВРОаренда</a>


                    <Offline><i className="fa fa-signal offline" alt="Нет связи"> </i> Нет связи</Offline>
                    <Online><i className="fa fa-signal online"> </i></Online>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"> </span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/about">О проекте</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/create">Создать</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/tech">Техника</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/">Главная</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/signup">Регистрация</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/login">Войти</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/logout">Выйти</NavLink>
                            </li>
                        </ul>
                        <form className="d-flex" role="search">
                            <input className="form-control me-2" type="search" placeholder="" aria-label="Search" />
                            <button className="btn btn-outline-success" type="submit">Найти</button>
                        </form>
                    </div>
                </div>
            </nav>
        </header>

    );
}

export default Header;
