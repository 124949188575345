import React, {useRef} from "react";
import {useState} from 'react';
import env from '../env.json';

import {YMaps, Map, Placemark, Clusterer, FullscreenControl, GeolocationControl, SearchControl, ZoomControl } from 'react-yandex-maps';

function Create() {
    const [info, setInfo] = useState('');
    const center = [59.929822185902985,30.314256079869256];

    let sendData = (obj) => {
        fetch(env.url+"/rent/ajax/app.php", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(obj)
        })
        .then (response => response.json())
        .then (response =>{
            console.log(response);
            if(response.message) {
                setInfo(response.message);
                console.log(response.message);
            }
        });
    };

    let loadDataFromForm = event => {
        event.preventDefault();
        setInfo(``);
        let name = event.target.elements.name.value;
        name = name.trim();
        if (name === '') {
            setInfo(`Заполните обязательные поля!`);
            return false;
        }
        let data = {
            'name' : name,
            'iduser' : localStorage.getItem('idUser'),
            'category' : event.target.elements.category.value,
            'price' : event.target.elements.price.value,
            'coords' : event.target.elements.coords.value,
            'detail' : event.target.elements.detail.value,
            'action': event.target.elements.action.value
        };
        //console.log(data);
        sendData(data);
    };

    // ============================
    const [maps, setMaps] = useState(null);
    const [address, setAddress] = useState("");
    const [coords, setCoords] = useState(center);
    const getGeoLocation = (e) => {
        let coord = e.get("target").getCenter();
        console.log(e.get("target").getCenter());
        setCoords(e.get("target").getCenter());

        let resp = maps.geocode(coord);
        resp.then((res) => {
            setAddress(res.geoObjects.get(0).getAddressLine());
        });
    };

    const onLoad = (map) => {
        setMaps(map);
    };

    function handleInputChange(e){
        setCoords(e.target.value);
    }
    // ============================
    return (
        <div className="page">
            <h1 className="page-title">Добавляем новый объект</h1>
            <form action="" onSubmit={loadDataFromForm}>
                <div className="card card-style">
                    <div className="content mb-0">

                        <div className="input-style has-borders has-icon mb-4">
                            <i className="fa fa-cog"> </i>
                            <input type="text" className="form-control" id="name" name="name" placeholder="Название" />
                            <label htmlFor="name" className="color-highlight">Название</label>
                            <em>(required)</em>
                        </div>

                        <div className="input-style has-borders no-icon mb-4">
                            <label htmlFor="category" className="color-highlight disabled">Категория</label>
                            <select id="category" name="category"
                                defaultValue = {{ label: "Выберите категорию", value: 0 }}
                            >
                                <option value="ekskavatory_pogruzchiki">Экскаваторы-погрузчики</option>
                                <option value="ekskavatory_kolesnye">Экскаваторы колесные</option>
                                <option value="ekskavatory_gusenichnye">Экскаваторы гусеничные</option>
                                <option value="mini_ekskavatory">Мини-экскаваторы</option>
                                <option value="pogruzchiki_frontalnye">Погрузчики фронтальные</option>
                                <option value="katki_gruntovye">Катки грунтовые</option>
                                <option value="vibrouplotnitelnaya_tekhnika">Виброуплотнительная техника</option>
                                <option value="podemnoe_oborudovanie">Подъемное оборудование</option>
                            </select>
                            <span><i className="fa fa-chevron-down"></i></span>
                        </div>

                        <div className="input-style has-borders no-icon mb-4">
                            <input type="text" className="form-control" id="price" name="price" placeholder="Стоимость" />
                            <label htmlFor="price" className="color-highlight">Стоимость</label>
                        </div>

                        <div className="input-style has-borders no-icon mb-4">
                            <input type="text" className="form-control" id="coords" name="coords" placeholder="" defaultValue={coords} value={coords} onChange={handleInputChange}/>
                            <label htmlFor="coords" className="color-highlight">Местоположение</label>
                        </div>

                        <div className="input-style has-borders no-icon mb-4">
                            <textarea className="form-control" id="detail" name="detail" placeholder="Описание" defaultValue="" />
                            <label htmlFor="detail" className="color-highlight">Описание</label>
                        </div>

                        <input type="hidden" name="action" value="add"/>
                        <button type="submit" className="btn btn-m btn-full rounded-sm shadow-xl bg-highlight text-uppercase mb-3 font-900">Добавить</button>

                    </div>
                </div>
            </form>

            <div className="card card-style">
                <div className="content">
                    {info}
                </div>
            </div>

            <div className="map-container">
                <div id="marker"> </div>
                <YMaps
                    enterprise
                    query={{
                        load: "package.full", // Отключаем модули. Используем стандартное поведение(полная загрузка)
                        apikey: "93077c0c-eb6d-4203-925d-b97833322127\n"
                    }}
                >
                    <Map
                        defaultState={{ center: center, zoom: 17 }}  // Хачапури и вино
                        width="100%"
                        height="500px"
                        onBoundsChange={(ymaps) => getGeoLocation(ymaps)}
                        modules={["geolocation", "geocode"]}
                        onLoad={(ymaps) => onLoad(ymaps)}
                    >
                    </Map>
                </YMaps>
            </div>



            {address}





        </div>

    );
}

export default Create;
