function About() {
    return (
        <div className="page">
            <div className="card card-style card-about-title">
                <div className="card-center ms-3">
                    <h1 className="color-white mb-0">Аренда спецтехники</h1>
                    <p className="color-white mt-n1 mb-0">О проекте</p>
                </div>
                <div className="card-overlay bg-black opacity-80"> </div>
            </div>

            <div className="card card-style">
                <div className="content mb-3">
                    <h3 className="mb-1">Новый подход </h3>
                    <p className="lh-base">
                        Доступный сервис для удобной аренды спецтехники по всеё России.
                    </p>
                    <div className="row text-center row-cols-3 mb-0">
                        <div className="col mb-4">
                            <img src="./imgs/terex-980-elite.jpg" className="img-fluid rounded-xs" alt="img" />
                        </div>
                        <div className="col mb-4">
                            <img src="./imgs/terex-980-elite.jpg" className="img-fluid rounded-xs" alt="img" />
                        </div>
                        <div className="col mb-4">
                            <img src="./imgs/terex-980-elite.jpg" className="img-fluid rounded-xs" alt="img" />
                        </div>
                        <div className="col mb-4">
                            <img src="./imgs/terex-980-elite.jpg" className="img-fluid rounded-xs" alt="img" />
                        </div>
                        <div className="col mb-4">
                            <img src="./imgs/terex-980-elite.jpg" className="img-fluid rounded-xs" alt="img" />
                        </div>
                        <div className="col mb-4">
                            <img src="./imgs/terex-980-elite.jpg" className="img-fluid rounded-xs" alt="img" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="card card-style">
                <div className="card card-style mx-0 rounded-0 mb-0" style = {{ backgroundImage: "url('./imgs/terex-980-elite.jpg')", height: '200px'}}>
                    <div className="card-bottom ms-3 mb-3">
                        <h1 className="color-white mb-0">ООО «ЕВРОПарк»</h1>
                    </div>
                    <div className="card-overlay bg-gradient rounded-0"> </div>
                </div>
                <div className="content lh-base">
                    <p>Спецтехника в аренду в нашей компании это гарантия безупречного сотрудничества по приемлемым ценам.
                        За счет грамотного подбора автопарка, использования только высококачественных расходных
                        материалов и запасных частей, постоянного контроля, дорожная и строительная техника,
                        арендуемая в ООО «ЕВРОпарк», отличается высокой надёжностью и производительностью.</p>
                    <a href="https://europark-tech.ru/" className="color-theme font-12"><i className="fa fa-link font-10 me-2"> </i>
                        Перейти на сайт ООО «ЕВРОпарк»</a><br />

                </div>
            </div>

            <div className="card card-style">
                <div className="card card-style mx-0 rounded-0 mb-0" style = {{ backgroundImage: "url('./imgs/terex-970-elite-2008.jpg')", height: "200px"}}>
                    <div className="card-bottom ms-3 mb-3">
                        <h1 className="color-white mb-0">ООО «ЕВРОПарк»</h1>
                    </div>
                    <div className="card-overlay bg-gradient rounded-0"> </div>
                </div>
                <div className="content lh-base">
                    <p>
                        Эксплуатационная масса, т:	8,244 <br/>
                        Объем ковша (передний):	1,2 <br/>
                        Объем ковша (задний):	0,28 <br/>
                        Объем ковша (планировочный):	0,4 <br/>
                        Максимальная глубина выработки грунта (стандартным ковшом):	4,4 <br/>
                        Максимальная глубина выработки грунта (с телескопом),м:	5,85 <br/>
                        Высота разгрузки погрузочным оборудованием, м:	2,75 <br/>

                    </p>
                    <a href="https://europark-tech.ru/" className="color-theme font-12"><i className="fa fa-link font-10 me-2"> </i>
                        Перейти на сайт ООО «ЕВРОпарк»</a><br />

                </div>
            </div>










        </div>
    );
}

export default About;
