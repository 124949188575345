function Error() {
    return (
        <div className="page">
            <h1>404</h1>
            <p>Страница или компонент не найдены!</p>
        </div>
    );
}

export default Error;
