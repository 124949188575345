import React, {useRef, useState} from "react";
import { NavLink } from "react-router-dom";

const URL_LOGIN = "https://europark-tech.ru/rent/ajax/login.php";

const getData = async (url, data) => {
    const rep = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            //'Content-Type': 'application/json'
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    });
    const json = await rep.json();
    return json;
};

function Login(props) {

    const refLogin = useRef(null);
    const refPassword = useRef(null);

    const [value,setValue] = useState();

    // const log = localStorage.getItem('log');
    //console.log("log in Login is", log);

    const handelLogin = async (event) => {
        event.preventDefault();
        const data = {
            login: refLogin.current.value,
            password: refPassword.current.value,
        };
        console.log(data);
        const data_JSON = await getData(URL_LOGIN, data);
        console.log("Mmm.... ", data_JSON.conectado);
        //props.setLog(data_JSON.conectado); // = logSet(est); (est useState) залогинен
        localStorage.setItem('logStorage', data_JSON.conectado); // залогиненность в logStorage из localStorage
        localStorage.setItem('idUser', data_JSON.id); // id пользователя в idUser  из localStorage
        document.querySelector(".form-alert").innerHTML = "";
        if(data_JSON.error) document.querySelector(".form-alert").innerHTML = data_JSON.error;
        else window.location.reload();
    };

    return (
        <div className="page form-page">

            <div className="card card-style">
                <div className="content mb-0">

                    <h2>Вход</h2>
                    <p className="mb-4 lh-base">
                        Владимир, ваш логин - <b>europark</b>, ваш пароль - <b>ABCD-EFGH</b>, где <b>ABCD</b> -
                        последние четыре цифры твоего и <a href="#">EFGH</a> - моего номеров телефона.
                    </p>

                    <form className="form">


                        <div className="input-style input-style-always-active has-borders has-icon">
                            <i className="fa fa-user font-12"> </i>
                            <input type="name" className="form-control" id="login" name="login" defaultValue="europark" ref={refLogin}/>
                            <label htmlFor="login" className="color-blue-dark">Login</label>
                            <em>(обязательно)</em>
                        </div>{" "}

                        <div className="input-style input-style-always-active has-borders has-icon mt-4">
                            <i className="fa fa-key font-12"> </i>
                            <input type="password" className="form-control" id="password" name="password" placeholder="" ref={refPassword} />
                            <label htmlFor="password" className="color-blue-dark">Password</label>
                            <em>(обязательно)</em>
                        </div>{" "}

                        <div className="alert alert-danger form-alert">Введите данные</div>

                    </form>

                    <NavLink className="nav-link" to="/about">
                        <button
                            onClick={handelLogin}
                            className="btn btn-full bg-green-dark btn-m text-uppercase rounded-sm shadow-l mb-3 mt-4 font-900"
                        >
                            Войти
                        </button>
                    </NavLink>

                </div>
            </div>

        </div>
    );
}


export default Login;
