import {NavLink} from "react-router-dom";
import React from "react";

function Main() {
    return (
        <div className="page">
            <div className="card-center text-center">
                <h1 className="page-title mb-5">Аренда спецтехники</h1>

                <div className="row mx-auto mt-3 mb-0">

                    <div className="col-4">
                        <NavLink className="icon icon-xxl bg-google rounded-xl mb-1" to="/about"><i className="fa fa-info-circle"> </i></NavLink>
                        <p>О проекте</p>
                    </div>
                    <div className="col-4">
                        <NavLink  to="/create" className="icon icon-xxl bg-blue-dark rounded-xl mb-1"><i className="fa fa-plus-square"> </i></NavLink>
                        <p>Добавить</p>
                    </div>
                    <div className="col-4">
                        <NavLink  to="/tech" className="icon icon-xxl bg-red-dark rounded-xl mb-1"><i className="fa fa-cogs"> </i></NavLink>
                        <p>Техника</p>
                    </div>
                    <div className="col-4">
                        <NavLink  to="/" className="icon icon-xxl bg-orange-dark rounded-xl mb-1"><i className="fa fa-home"> </i></NavLink>
                        <p>Главная</p>
                    </div>
                    <div className="col-4">
                        <a href="/contacts" className="icon icon-xxl bg-green-dark rounded-xl mb-1"><i className="fa fa-map-marker-alt"> </i></a>
                        <p>Контакты</p>
                    </div>
                    <div className="col-4">
                        <a href="#"  className="icon icon-xxl bg-brown-dark rounded-xl mb-1"><i className="fa fa-user"> </i></a>
                        <p>Личный кабинет</p>
                    </div>
                    <div className="col-4">
                        <a href="tel:+79219511013" className="default-link icon icon-xxl bg-green-dark rounded-xl mb-1"><i className="fa fa-phone"> </i></a>
                        <p>Позвонить</p>
                    </div>
                    <div className="col-4">
                        <a href="/message" className="icon icon-xxl bg-blue-dark rounded-xl mb-1"><i className="fa fa-envelope"> </i></a>
                        <p>Написать</p>
                    </div>

                </div>


                <p className="opacity-60 font-10">Сделано в Студии Миркина</p>
            </div>
        </div>

    );
}

export default Main;
