function Contacts() {
    return (
        <>
            <div className="page">

                <div className="card card-style">
                    <div className="content mb-0">
                        <h3 className="font-700">Контакты</h3>
                        <p className="pb-0 mb-0">196084, г. Санкт-Петербург</p>
                        <p className="pb-0 mb-0">ул. Заставская, д.7, лит. 3</p>
                        <div className="divider mt-3"> </div>
                        <h3 className="font-700">ООО «ЕВРОпарк»</h3>
                        <p className="pb-0 mb-0">ИНН 7810518121</p>
                        <p className="pb-0 mb-0">КПП 781001001</p>

                        <div className="list-group list-custom-small">
                            <a className="default-link" href="tel:+7 (812) 44-99-639">
                                <i className="fa font-14 fa-phone color-phone"> </i>
                                <span>+7 (812) 44-99-639</span>
                                <span className="badge bg-red-dark">Позвонить</span>
                                <i className="fa fa-angle-right"> </i>
                            </a>
                            <a className="default-link" href="mailto:office@europark-tech.ru">
                                <i className="fa font-14 fa-envelope color-mail"> </i>
                                <span>office@europark-tech.ru</span>
                                <span className="badge bg-red-dark">Написать</span>
                                <i className="fa fa-angle-right"> </i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>



        </>
    );
}

export default Contacts;



